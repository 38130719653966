import React from 'react';
import PageTransition from '../components/PageTransition'; // Importamos PageTransition
import UsersList from '../components/UserList';
// Eliminamos la importación de Toasts
import Toasts from '../components/Toasts';

const Users = () => {
  return (
    <PageTransition>
      {/* Aquí va el contenido de la vista de Usuarios */}
      <UsersList />
      {/* Eliminamos el contenedor de Toasts */}
      <Toasts /> 
    </PageTransition>
  );
};

export default Users;
