import React, { useState, useEffect, useRef, useCallback } from 'react';
import axios from 'axios';
import Select from 'react-select';
import { ReactMediaRecorder } from 'react-media-recorder';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaperclip, faMicrophone, faPaperPlane } from '@fortawesome/free-solid-svg-icons';
import { ClipLoader } from 'react-spinners';
import { toast } from 'react-toastify';

function ChatComponent() {
  const [contacts, setContacts] = useState([]);
  const [selectedContacts, setSelectedContacts] = useState([]);
  const [message, setMessage] = useState('');
  const [chatMessages, setChatMessages] = useState([]);
  const [isSending, setIsSending] = useState(false);
  const [isRecording, setIsRecording] = useState(false);
  const [shouldScroll, setShouldScroll] = useState(false); // Nuevo estado
  const fileInputRef = useRef(null);
  const chatEndRef = useRef(null);

  const API_URL = process.env.REACT_APP_API_URL;
  const CONTACTS_URL = process.env.REACT_APP_CONTACTS_URL;

  // Función para formatear la fecha y hora en formato 12 horas con AM/PM
  function formatDateTime(date) {
    const day = date.getDate().toString().padStart(2, '0'); // Día con dos dígitos
    const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Mes con dos dígitos
    const year = date.getFullYear();

    let hours = date.getHours(); // Horas en formato 24 horas
    const minutes = date.getMinutes().toString().padStart(2, '0'); // Minutos con dos dígitos
    const ampm = hours >= 12 ? 'PM' : 'AM'; // Determinar AM o PM

    hours = hours % 12;
    hours = hours ? hours : 12; // El '0' se convierte en '12'
    hours = hours.toString().padStart(2, '0'); // Horas con dos dígitos

    return `${day}/${month}/${year} ${hours}:${minutes} ${ampm}`;
  }

  const fetchChatHistory = useCallback(async () => {
    try {
      const { data } = await axios.get(`${API_URL}/getChatHistory`);
      setChatMessages(data);
    } catch (error) {
      toast.error('Error al recuperar el historial de chat');
    }
  }, [API_URL]);

  useEffect(() => {
    fetchChatHistory();
    const intervalId = setInterval(() => {
      fetchChatHistory();
    }, 5000);
    return () => clearInterval(intervalId);
  }, [fetchChatHistory]);

  useEffect(() => {
    const fetchContacts = async () => {
      try {
        const { data } = await axios.get(CONTACTS_URL);
        const formattedContacts = data.map((contact) => ({
          value: contact.id,
          label: contact.nombre,
        }));
        setContacts([{ value: 'all', label: 'Seleccionar Todos' }, ...formattedContacts]);
      } catch (error) {
        toast.error('Error al cargar los contactos');
      }
    };

    fetchContacts();
    const intervalId = setInterval(() => {
      fetchContacts();
    }, 5000);
    return () => clearInterval(intervalId);
  }, [CONTACTS_URL]);

  const handleChange = (selectedOptions) => {
    if (selectedOptions.some((option) => option.value === 'all')) {
      setSelectedContacts(contacts.filter((contact) => contact.value !== 'all'));
    } else {
      setSelectedContacts(selectedOptions);
    }
  };

  const handleFileChange = async (e) => {
    if (selectedContacts.length === 0) {
      toast.warn('Debe seleccionar al menos un contacto.');
      return;
    }

    const selectedFile = e.target.files[0];
    if (!selectedFile) return;

    setIsSending(true);

    const fileType = selectedFile.type.startsWith('image/')
      ? 'photo'
      : selectedFile.type.startsWith('audio/')
        ? 'audio'
        : selectedFile.type.startsWith('video/')
          ? 'video'
          : 'document';

    const date = formatDateTime(new Date()); // Usamos formatDateTime

    const fileMessage = {
      type: fileType,
      content: selectedFile.name,
      sender: 'me',
      date: date,
    };

    setChatMessages((prevMessages) => [...prevMessages, fileMessage]);
    setShouldScroll(true); // Activar scroll

    await sendFile(selectedFile, fileType);

    setIsSending(false);

    fetchChatHistory();
  };

  const sendFile = async (fileToSend, fileType) => {
    const formData = new FormData();
    formData.append('file', fileToSend);
    formData.append('chatIds', JSON.stringify(selectedContacts.map((user) => user.value)));
    formData.append('type', fileType);

    try {
      await axios.post(`${API_URL}/sendFile`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      toast.success('Archivo enviado correctamente.');
    } catch (error) {
      toast.error('Error al enviar el archivo.');
    }
  };

  const handleSendMessage = async () => {
    if (selectedContacts.length === 0) {
      toast.warn('Debe seleccionar al menos un contacto.');
      return;
    }

    if (!message.trim()) {
      toast.warn('Debe escribir texto para enviar.');
      return;
    }

    setIsSending(true);

    const date = formatDateTime(new Date()); // Usamos formatDateTime

    const newMessage = {
      type: 'text',
      content: message,
      sender: 'me',
      date: date,
    };

    setChatMessages((prevMessages) => [...prevMessages, newMessage]);
    setShouldScroll(true); // Activar scroll

    try {
      await axios.post(`${API_URL}/sendMessage`, {
        chatIds: selectedContacts.map((user) => user.value),
        text: message,
      });
      toast.success('Mensaje enviado correctamente.');
      setMessage('');

      fetchChatHistory();
    } catch (error) {
      toast.error('Error al enviar el mensaje.');
    } finally {
      setIsSending(false);
    }
  };

  const sendAudioMessage = async (audioFile, audioURL) => {
    if (selectedContacts.length === 0) {
      toast.warn('Debe seleccionar al menos un contacto.');
      return;
    }

    setIsSending(true);

    const date = formatDateTime(new Date()); // Usamos formatDateTime

    const newAudioMessage = {
      type: 'audio',
      content: audioURL,
      sender: 'me',
      date: date,
    };

    setChatMessages((prevMessages) => [...prevMessages, newAudioMessage]);
    setShouldScroll(true); // Activar scroll

    await sendFile(audioFile, 'audio');

    setIsSending(false);

    fetchChatHistory();
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      handleSendMessage();
    }
  };

  useEffect(() => {
    if (shouldScroll) {
      chatEndRef.current?.scrollIntoView({ behavior: 'smooth' });
      setShouldScroll(false); // Restablecer scroll
    }
  }, [shouldScroll]);

  return (
    <div className="flex flex-col rounded-lg w-full h-full">
      {/* Selector de contactos */}
      <div className="mb-4">
        <Select
          label="Seleccionar contactos"
          isMulti
          options={contacts}
          value={selectedContacts}
          onChange={handleChange}
          className="basic-multi-select"
          classNamePrefix="select"
          placeholder="Buscar y seleccionar contactos"
        />
      </div>

      {/* Área de mensajes */}
      <div className="flex-grow mb-4 h-[calc(87vh-140px)] overflow-y-auto bg-gray-200 p-4 rounded">
        {chatMessages.map((msg, index) => {
          // Formatear la fecha y hora si es necesario
          let formattedDate = msg.date;
          if (msg.date) {
            const dateObj = new Date(msg.date);
            if (!isNaN(dateObj)) {
              formattedDate = formatDateTime(dateObj);
            }
          }

          return (
            <div
              key={index}
              className={`mb-2 p-2 rounded ${
                msg.sender === 'me' ? 'bg-blue-200 text-right ml-auto' : 'bg-gray-300 text-left'
              }`}
              style={{ maxWidth: '70%', wordWrap: 'break-word' }}
            >
              {msg.type === 'text' && <p>{msg.content}</p>}
              {msg.type === 'photo' && (
                <div>
                  <img
                    src={`${API_URL}/files/${msg.content}`}
                    alt="Imagen"
                    style={{ maxWidth: '100%', borderRadius: '8px' }}
                  />
                </div>
              )}
              {msg.type === 'video' && (
                <div>
                  <video controls style={{ maxWidth: '100%', borderRadius: '8px' }}>
                    <source src={`${API_URL}/files/${msg.content}`} type="video/mp4" />
                    Tu navegador no soporta el elemento de video.
                  </video>
                </div>
              )}
              {msg.type === 'audio' && (
                <div>
                  <audio controls src={`${API_URL}/files/${msg.content}`} style={{ width: '100%' }}></audio>
                </div>
              )}
              {msg.type === 'document' && (
                <div>
                  <p>
                    <FontAwesomeIcon icon={faPaperclip} />{' '}
                    <a href={`${API_URL}/files/${msg.content}`} target="_blank" rel="noopener noreferrer">
                      {msg.content}
                    </a>
                  </p>
                </div>
              )}
              <small className="text-gray-600 block text-right mt-1">{formattedDate}</small>
            </div>
          );
        })}
        <div ref={chatEndRef} />
      </div>

      {/* Área de entrada */}
      <div className="relative flex items-center bg-white rounded-full p-2">
        <input
          type="text"
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          placeholder="Mensaje"
          className="flex-grow bg-transparent outline-none text-gray px-3"
          onKeyPress={handleKeyPress}
        />

        <button className="text-gray-500 px-2" onClick={() => fileInputRef.current.click()}>
          <FontAwesomeIcon icon={faPaperclip} size="lg" />
        </button>

        <input
          type="file"
          ref={fileInputRef}
          style={{ display: 'none' }}
          onChange={handleFileChange}
        />

        <ReactMediaRecorder
          audio
          audioType="ogg"
          onStop={(blobUrl, blob) => {
            if (!blob || blob.size === 0) {
              toast.error('Error al grabar el audio. Inténtalo de nuevo.');
              return;
            }
            const audioURL = URL.createObjectURL(blob);
            const audioFile = new File([blob], 'audioMessage.ogg', { type: 'audio/ogg' });
            sendAudioMessage(audioFile, audioURL);
          }}
          render={({ startRecording, stopRecording }) => (
            <button
              onClick={() => {
                if (isRecording) {
                  stopRecording();
                  setIsRecording(false);
                } else {
                  startRecording();
                  setIsRecording(true);
                }
              }}
              className={`p-2 rounded-full ml-2 ${isRecording ? 'bg-red-500' : 'bg-blue-500'} text-white transition-transform transform hover:scale-105`}
            >
              <FontAwesomeIcon icon={faMicrophone} />
            </button>
          )}
        />

        <button
          onClick={handleSendMessage}
          className="bg-blue-500 text-white p-2 rounded-full ml-2 transition-transform transform hover:scale-105"
          disabled={isSending}
        >
          {isSending ? <ClipLoader size={20} color={'#fff'} /> : <FontAwesomeIcon icon={faPaperPlane} />}
        </button>
      </div>
    </div>
  );
}

export default ChatComponent;
