import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import PageTransition from '../components/PageTransition'; // Importamos el componente de transición
import Toasts from '../components/Toasts'; // Importamos el componente de Toasts
import { toast } from 'react-toastify'; // Importar directamente el toast para usarlo

const Login = ({ onLogin }) => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();

  const handleSubmit = (e) => {
    e.preventDefault();

    // Comparar con las variables de entorno
    const envUsername = process.env.REACT_APP_LOGIN_USERNAME;
    const envPassword = process.env.REACT_APP_LOGIN_PASSWORD;

    if (username === envUsername && password === envPassword) {
      onLogin(); // Llama a la función de login
      toast.success('Inicio de sesión exitoso');
      navigate('/'); // Redirige a la página principal
    } else {
      toast.error('Credenciales incorrectas');
    }
  };

  return (
    <PageTransition>
      <div className="flex items-center justify-center h-screen">
        <div className="bg-white p-6 rounded-lg shadow-md text-center bg-opacity-00 backdrop-blur-md">
          <div className="flex justify-center mb-4">
            <img src="logo1000rifas.png" alt="Logo" className="h-12" />
          </div>
          <h2 className="text-2xl font-bold mb-4">Iniciar Sesión</h2>
          <form onSubmit={handleSubmit}>
            <input
              className="border p-2 w-full mb-4 rounded-lg"
              type="text"
              placeholder="Usuario"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
            />
            <input
              className="border p-2 w-full mb-4 rounded-lg"
              type="password"
              placeholder="Contraseña"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <button className="bg-blue-500 text-white p-2 w-full rounded-lg hover:bg-blue-600 transition-colors duration-200">
              Iniciar Sesión
            </button>
          </form>
        </div>
        <Toasts /> {/* Usamos el componente Toasts aquí */}
      </div>
    </PageTransition>
  );
};

export default Login;
