import React from 'react';
import PageTransition from '../components/PageTransition'; // Componente de transición
import ChatComponent from '../components/ChatComponent'; // Componente del chat
// Eliminamos la importación de Toasts
import Toasts from '../components/Toasts';

const Chat = () => {
  return (
    <PageTransition>
      <div className="flex flex-col items-center justify-center">
        {/* Contenido principal del chat */}
        <ChatComponent />
        {/* Eliminamos el contenedor de Toasts */}
        <Toasts /> 
      </div>
    </PageTransition>
  );
};

export default Chat;
