import React, { useState, useEffect } from 'react';
import axios from 'axios';
import CustomModal from './Modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';
import { toast } from 'react-toastify'; // Importamos el toast de 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'; // Asegúrate de que el CSS está cargado una sola vez

const UsersList = () => {
  const [users, setUsers] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [newUserName, setNewUserName] = useState('');

  const API_URL = process.env.REACT_APP_CONTACTS_URL;

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const { data } = await axios.get(API_URL);
        setUsers(data);
      } catch (error) {
        console.error('Error fetching users:', error);
        toast.error('Error al cargar los usuarios.');
      }
    };

    fetchUsers();
  }, [API_URL]);

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const filteredUsers = users.filter(
    (user) =>
      user.nombre.toLowerCase().includes(searchQuery.toLowerCase()) ||
      user.apellido.toLowerCase().includes(searchQuery.toLowerCase()) ||
      user.usuario.toLowerCase().includes(searchQuery.toLowerCase()) ||
      user.telefono.includes(searchQuery) ||
      user.id.toString().includes(searchQuery)
  );

  const handleDeleteClick = (user) => {
    setSelectedUser(user);
    setIsModalOpen(true);
  };

  const handleConfirmDelete = () => {
    setIsModalOpen(false);
    setUsers(users.filter((user) => user.id !== selectedUser.id));
    toast.success(`Usuario ${selectedUser.nombre} eliminado con éxito.`);
  };

  const handleEditClick = (user) => {
    setSelectedUser(user);
    setNewUserName(user.nombre);
    setIsEditModalOpen(true);
  };

  const handleConfirmEdit = () => {
    const updatedUsers = users.map((user) =>
      user.id === selectedUser.id ? { ...user, nombre: newUserName } : user
    );
    setUsers(updatedUsers);
    setIsEditModalOpen(false);
    toast.success(`Usuario ${selectedUser.nombre} editado con éxito.`);
  };

  return (
    <div className="w-full p-4">
      <h2 className="text-2xl font-bold mb-4 text-gray-900">Lista de Contactos</h2>

      {/* Buscador */}
      <div className="mb-4">
        <input
          type="text"
          value={searchQuery}
          onChange={handleSearchChange}
          placeholder="Buscar por nombre, apellido, usuario, teléfono o ID"
          className="p-2 border border-gray-300 rounded-lg w-full"
        />
      </div>

      {/* Tabla de usuarios */}
      <div className="overflow-x-auto">
        <table className="min-w-full bg-white">
          <thead>
            <tr>
              <th className="py-2 px-4 border-b text-left">ID</th>
              <th className="py-2 px-4 border-b text-left">Nombre</th>
              <th className="py-2 px-4 border-b text-left">Apellido</th>
              <th className="py-2 px-4 border-b text-left">Usuario</th>
              <th className="py-2 px-4 border-b text-left">Teléfono</th>
              <th className="py-2 px-4 border-b text-left">Acciones</th>
            </tr>
          </thead>
          <tbody>
            {filteredUsers.map((user) => (
              <tr key={user.id}>
                <td className="py-2 px-4 border-b">{user.id}</td>
                <td className="py-2 px-4 border-b">{user.nombre}</td>
                <td className="py-2 px-4 border-b">{user.apellido}</td>
                <td className="py-2 px-4 border-b">{user.usuario}</td>
                <td className="py-2 px-4 border-b">{user.telefono}</td>
                <td className="py-2 px-4 border-b">
                  <button
                    onClick={() => handleEditClick(user)}
                    className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 mr-2"
                  >
                    <FontAwesomeIcon icon={faEdit} /> {/* Icono de editar */}
                  </button>
                  <button
                    onClick={() => handleDeleteClick(user)}
                    className="px-4 py-2 bg-red-500 text-white rounded hover:bg-red-600"
                  >
                    <FontAwesomeIcon icon={faTrash} /> {/* Icono de eliminar */}
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {/* Modal de confirmación de eliminación */}
      <CustomModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        onConfirm={handleConfirmDelete}
        title="Confirmación"
        message={`¿Estás seguro de que deseas eliminar a ${selectedUser?.nombre}?`}
      />

      {/* Modal de edición de usuario */}
      <CustomModal
        isOpen={isEditModalOpen}
        onClose={() => setIsEditModalOpen(false)}
        onConfirm={handleConfirmEdit}
        title="Editar Usuario"
        message={
          <div>
            <label className="block mb-2">Nuevo nombre:</label>
            <input
              type="text"
              value={newUserName}
              onChange={(e) => setNewUserName(e.target.value)}
              className="w-full p-2 border rounded-lg"
            />
          </div>
        }
      />
    </div>
  );
};

export default UsersList;
