import React, { useState, useEffect } from 'react';
import { FiWifi, FiWifiOff } from 'react-icons/fi';

function Connection() {
  const [isOnline, setIsOnline] = useState(navigator.onLine);

  useEffect(() => {
    function handleOnline() {
      setIsOnline(true);
    }

    function handleOffline() {
      setIsOnline(false);
    }

    window.addEventListener('online', handleOnline);
    window.addEventListener('offline', handleOffline);

    return () => {
      window.removeEventListener('online', handleOnline);
      window.removeEventListener('offline', handleOffline);
    };
  }, []);

  const badgeColor = isOnline ? 'bg-green-500' : 'bg-red-500';
  const connectionText = isOnline ? 'En línea' : 'Sin conexión';
  const Icon = isOnline ? FiWifi : FiWifiOff;

  return (
    <div className={`inline-flex items-center px-3 py-1 rounded-full text-white ${badgeColor}`}>
      <Icon className="mr-1" size={20} />
      <span className="text-sm font-medium">{connectionText}</span>
    </div>
  );
}

export default Connection;
