import React from 'react';
import { Link } from 'react-router-dom'; // Importa el componente Link

const Logo = ({ className }) => {
  return (
    <div className={className}>
      <Link to="/"> {/* Reemplazamos <a> por <Link> */}
        <img src="logo1000rifas.png" alt="Logo" className="h-9" />
      </Link>
    </div>
  );
};

export default Logo;
