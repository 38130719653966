import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';

Modal.setAppElement('#root');

const CustomModal = ({ isOpen, onClose, onConfirm, title, message }) => {
  const [zoomType, setZoomType] = useState('scale-90'); // Estado inicial para el zoom

  useEffect(() => {
    if (isOpen) {
      setZoomType('scale-100'); // Aplica zoom in al abrir
    } else {
      setZoomType('scale-50'); // Aplica zoom out al cerrar
    }
  }, [isOpen]);

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      className={{
        base: 'fixed inset-0 flex items-center justify-center z-50',
        afterOpen: 'transition-transform duration-300', // Transición suave
        beforeClose: 'transition-transform duration-300', // Zoom out
      }}
      overlayClassName={{
        base: 'fixed inset-0 bg-black bg-opacity-50',
        afterOpen: 'opacity-100 transition-opacity duration-300', // Fade in for background
        beforeClose: 'opacity-0 transition-opacity duration-300', // Fade out for background
      }}
      closeTimeoutMS={300} // Duración de la animación
    >
      <div
        className={`bg-white rounded-lg shadow-lg p-6 w-full max-w-md transform transition-transform duration-300 ${zoomType}`}
      >
        <h2 className="text-xl font-semibold mb-4">{title}</h2>
        <div className="mb-6">{message}</div>
        <div className="flex justify-end space-x-4">
          <button
            onClick={onClose}
            className="px-4 py-2 bg-gray-500 text-white rounded-lg hover:bg-gray-600 transition-colors"
          >
            Cancelar
          </button>
          <button
            onClick={onConfirm}
            className="px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600 transition-colors"
          >
            Confirmar
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default CustomModal;
