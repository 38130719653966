import Connection from './components/Connection';  // Ajusta la ruta según tu estructura de carpetas
import { Route, Routes, Link, useLocation, Navigate, useNavigate } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { AnimatePresence } from 'framer-motion';
import Chat from './views/Chat';
import Users from './views/Users';
import Login from './views/Login'; // Vista de Login
import NotFound from './views/NotFound'; // Vista de 404
import Logo from './components/Logo';
import { MenuIcon, LogOutIcon, MessageSquareIcon, UserIcon, XIcon } from 'lucide-react'; // Íconos
import CustomModal from './components/Modal'; // Componente del modal
import PrivateRoute from './components/PrivateRoute'; // PrivateRoute

function App() {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [showLogoutModal, setShowLogoutModal] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();

  const toggleSidebar = () => setSidebarOpen(!sidebarOpen);

  const handleLogin = () => {
    setIsLoggedIn(true);
    localStorage.setItem('isLoggedIn', 'true'); // Guardamos la sesión en localStorage
  };

  const handleLogout = () => {
    setIsLoggedIn(false);
    localStorage.removeItem('isLoggedIn'); // Eliminamos la sesión
    setShowLogoutModal(false);
    navigate('/'); // Redirigimos al login después de cerrar sesión
  };

  const confirmLogout = () => {
    setSidebarOpen(false); // Cerramos el sidebar
    setShowLogoutModal(true); // Muestra el modal para confirmar el logout
  };

  useEffect(() => {
    // Revisamos si hay sesión guardada en localStorage
    const loggedIn = localStorage.getItem('isLoggedIn');
    if (loggedIn === 'true') {
      setIsLoggedIn(true);
    }
  }, []);

  const menuItems = [
    { id: 1, title: 'Chat', path: '/chat', icon: <MessageSquareIcon className="mr-2 h-4 w-4" /> },
    { id: 2, title: 'Usuarios', path: '/usuarios', icon: <UserIcon className="mr-2 h-4 w-4" /> },
  ];

  return (
    <div className={`flex h-screen ${!isLoggedIn ? 'overflow-hidden' : ''}`}>
      {/* Mostramos Sidebar y Navbar solo si el usuario está logueado */}
      {isLoggedIn && (
        <>
          {/* Overlay que aparece cuando el sidebar está abierto */}
          {sidebarOpen && (
            <div
              className="fixed inset-0 bg-black bg-opacity-50 z-40 lg:hidden"
              onClick={() => setSidebarOpen(false)}
            ></div>
          )}

          <aside
            className={`${sidebarOpen ? 'translate-x-0' : '-translate-x-full'
              } fixed inset-y-0 left-0 z-50 w-64 bg-white shadow-lg transition-transform duration-300 ease-in-out lg:translate-x-0 lg:static lg:inset-0`}
          >
            <div className="flex flex-col h-full">
              <header className="flex items-center justify-center h-16 px-4">
                <Logo className="flex items-center" />
              </header>
              <nav className="flex-1 overflow-y-auto px-2 mt-5">
                <ul>
                  {menuItems.map((item) => (
                    <li key={item.id}>
                      <Link
                        to={item.path}
                        onClick={() => setSidebarOpen(false)} // Cerramos el sidebar al hacer clic
                        className={`w-full flex items-center px-4 py-2 text-sm font-medium rounded-md mb-2 ${location.pathname === item.path
                            ? 'bg-mariner-700 text-white'
                            : 'text-gray-700 hover:bg-mariner-200'
                          }`}
                      >
                        {item.icon}
                        {item.title}
                      </Link>
                    </li>
                  ))}
                </ul>
              </nav>
              <footer className="p-4 border-t border-gray-200">
                <button
                  className="w-full flex items-center px-4 py-2 mt-2 text-sm font-medium text-gray-700 rounded-md hover:bg-mariner-200"
                  onClick={confirmLogout}
                >
                  <LogOutIcon className="mr-2 h-4 w-4" />
                  Cerrar sesión
                </button>
              </footer>
            </div>
          </aside>
        </>
      )}

      <div className="flex-1 flex flex-col overflow-hidden">
        {/* Mostramos el header solo si el usuario está logueado */}
        {isLoggedIn && (
          <header className="bg-white border-b border-gray-200 shadow-sm z-10">
            <div className="max-w-7xl mx-auto py-4 px-4 sm:px-6 lg:px-8 flex items-center justify-between">
              {/* Logo visible solo en dispositivos móviles */}
              <Logo className="block lg:hidden" />
              {/* CONEXION A INTERNET */}
              <Connection />
              <button
                onClick={toggleSidebar}
                className="p-2 rounded-md lg:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200"
              >
                {sidebarOpen ? (
                  <XIcon className="h-6 w-6 text-gray-600" />
                ) : (
                  <MenuIcon className="h-6 w-6 text-gray-600" />
                )}
              </button>
            </div>
          </header>
        )}

        <main
          className={`flex-1 ${!isLoggedIn ? 'h-screen overflow-hidden' : 'overflow-x-hidden overflow-y-auto'
            }`}
        >
          <section className="max-w-screen-xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
            <AnimatePresence mode="wait">
              <Routes location={location} key={location.pathname}>
                {/* Si el usuario no está logueado */}
                {!isLoggedIn ? (
                  <>
                    {/* Redirigir a Login en rutas conocidas */}
                    <Route path="/" element={<Login onLogin={handleLogin} />} />
                    <Route path="/chat" element={<Login onLogin={handleLogin} />} />
                    <Route path="/usuarios" element={<Login onLogin={handleLogin} />} />

                    {/* Rutas desconocidas redirigen a 404 */}
                    <Route path="*" element={<NotFound />} />
                  </>
                ) : (
                  <>
                    {/* Rutas conocidas cuando está logueado */}
                    <Route
                      path="/chat"
                      element={
                        <PrivateRoute isLoggedIn={isLoggedIn}>
                          <Chat />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      path="/usuarios"
                      element={
                        <PrivateRoute isLoggedIn={isLoggedIn}>
                          <Users />
                        </PrivateRoute>
                      }
                    />

                    {/* Ruta base redirige a Chat si está logueado */}
                    <Route path="/" element={<Navigate to="/chat" />} />

                    {/* Rutas desconocidas muestran 404 */}
                    <Route path="*" element={<NotFound />} />
                  </>
                )}
              </Routes>
            </AnimatePresence>
          </section>
        </main>
      </div>

      {/* Modal de confirmación de cierre de sesión */}
      <CustomModal
        isOpen={showLogoutModal}
        onClose={() => setShowLogoutModal(false)} // Cierra el modal si se cancela
        onConfirm={handleLogout} // Cierra sesión si se confirma
        title="Confirmar cierre de sesión"
        message="¿Estás seguro de que deseas cerrar sesión?"
      />
    </div>
  );
}

export default App;